@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container {
    background-color: black;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5em;
    color: #EB0028;
}

.neon-text {
    color: #D11A2A;
    text-shadow: 
      0 0 5px rgba(255, 69, 69, 0.6),  /* Reduced glow intensity */
      0 0 10px rgba(255, 69, 69, 0.4); /* Softer glow */
    font-weight: bold;
    font-family: 'Montserrat', sans-serif; /* Apply Montserrat */
}

.speaker,
.performer {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three speakers per row */
    gap: 50px;
    justify-items: center;
    margin: auto;
    padding-top: 2rem;
    max-width: 60%;
    cursor: pointer;
}

.box,
.box1 {
    width: 100%; /* Make images responsive within the grid */
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
    transition: all 0.3s ease;
}

.box:hover,
.box1:hover {
    transform: scale(1.2);
    background-color: black;
    z-index: 2;
}

.popup-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px); /* Blurs the background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Darkens the background */
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .popup-small {
    background-color: rgb(23, 23, 23);
    color: white;
    padding: 3rem;
    max-width: 60%;
    max-height: 75%;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  }

  .no-scroll {
    overflow: hidden;
  }
  
  .popup-image {
    width: 20%;
    height: 20%;
    border-radius: 8px;
  }
  
  .popup-description {
    margin-top: 15px;
  }

  .popup-description p {
    text-align: left;
  }
  
  .speaker-name {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #EB0028;
  }  

.close-button {
    position: absolute;
    top: 15px;
    left: 15px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.close-button:hover {
    transform: scale(1.1);
}

.reveal {
    margin-top: 5rem;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 3rem;
}

@media (max-width: 768px) {
    .title {
        font-size: 2em;
        margin-bottom: 30px;
    }

    .speaker,
    .performer {
        padding: 0 !important;
        gap: 30px;
    }

    .box,
    .box1 {
        height: 200px;
    }

    .popup-small {
        padding: 1.5rem;
        max-width: 85%;
        max-height: 85%;
    }

    .popup-image {
        width: 30%;
        height: 30%;
    }

    .speaker-name {
        font-size: 1.2em;
    }

    .popup-description p {
        font-size: 0.8em;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 1.8em;
    }

    .speaker, .performer {
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
    }

    .box,
    .box1 {
        height: auto;
    }

    .popup-fullscreen {
        justify-content: left;
        margin-left: 0.5rem;
    }

    .popup-small {
        padding: 1rem;
        max-width: 75%;
        max-height: 85%;
    }

    .popup-image {
        width: 30%;
        height: 30%;
    }

    .speaker-name {
        font-size: 1em;
    }

    .popup-description p {
        font-size: 0.75em;
    }

    .close-button {
        font-size: 1.5rem;
        top: 10px;
        left: 10px;
    }
}

