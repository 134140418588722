@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.team-page {
    font-family: "Montserrat", sans-serif;
    background-color: #000;
}

.back-to-home {
    margin-top: 5rem; /* Add spacing */
    margin-left: 5rem;
    padding: 10px 20px;
    background: transparent;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background 0.3s ease;
}

.back-to-home:hover {
    background-color: #b2221a; /* Darker shade on hover */
}


.team-section {
    font-weight: 900;
    background-color: #000;
    padding: 60px 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .domain-name {
    color: #e62b1e;
    font-size: 2.8rem;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 700;
  }
  
  .domain-group {
    margin-bottom: 60px;
  }
  
  .members-list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    padding: 0 20px;
  }
  
  .team-member {
    background: rgba(58, 58, 58, 0.244);
    border-radius: 15px;
    width: 350px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, background 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .team-member:hover {
    transform: translateY(-5px);
    background: rgba(158, 22, 22, 0.128);
  }
  
  .profile-pic {
    width: 200px;
    height: 250px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px; /* Increased space below profile pic */
    margin-top: 20px; /* Increased space above profile pic */
  }
  
  .member-info {
    display: flex;
    flex-direction: column;
  }
  
  .member-name {
    color: #e62b1e;
    font-size: 1.7rem;
    font-weight: 700;
    margin: 5px 0;
  }
  
  .member-lastname {
    color: #ffffff;
    font-size: 1rem;
    opacity: 0.8;
    margin-top: 0px; /* Increased space above last name */
    margin-bottom: 10px; /* Increased space below last name */
  }

  .background {
    position: relative;
    width: 100%;
    min-height: 75vh;
    border-bottom: 2px solid red;
  }
  
  .overlay {
    min-height: 75vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .content {
    max-width: 1200px;
    width: 100%;
    display: flex;
    gap: 40px;
    align-items: center;
    padding: 20px;
  }
  
  .image-sec {
    flex: 1;
    max-width: 600px;
  }
  
  .image {
    width: 100%;
    max-width: 600px; /* Fixed image length */
    height: auto;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
  }
  
  .subtext {
    color: #fff;
    text-align: center;
    margin-top: 10px;
    font-size: 0.9em;
  }
  
  .side-text {
    flex: 1;
    color: #fff;
    text-align: center;
  }
  
  .title {
    font-size: 4.5em;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1;
  }
  
  .ted {
    color: #e62b1e;
  }
  
  .tedx {
    color: #e62b1e;
    font-size: 0.7em;
    vertical-align: super;
  }
  
  .pesu {
    color: #fff;
  }
  
  .text {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  /* Previous responsiveness conditions and additional updates */
  @media (max-width: 950px) {
    .overlay {
      min-height: 75vh;
    }

    .background {
      min-height: auto;
    }
    .team-page {
        width: 100%;
    }
    .content {
      flex-direction: column;
      text-align: center;
    }
  
    .image-sec {
      width: 80%;
      max-width: 450px; /* Ensure fixed image size */
      margin: 0 auto;
    }
  
    .title {
      font-size: 3.5em;
    }
  
    .text {
      font-size: 1em;
    }
  }
  
  @media (max-width: 500px) {
    .overlay {
      padding: 15px;
      min-height: 65vh;
    }

    .background {
      min-height: auto;
    }
  
    .content {
      gap: 20px;
      padding: 10px;
    }
  
    .title {
      font-size: 2.5em;
    }
  
    .text {
      font-size: 0.9em;
    }
  }
  
  /* Responsive layout adjustments */
  @media (max-width: 500px) {
    .domain-name {
      font-size: 2rem; /* Smaller font size for domain name on smaller screens */
    }
  
    .team-member {
      width: 90%;
      padding: 15px;
    }
  
    .profile-pic {
      width: 150px;
      height: 200px;
      margin-bottom: 15px; /* Adjusted margin for smaller screens */
    }
  
    .member-name {
      font-size: 1.4rem;
    }
  
    .member-lastname {
      font-size: 0.9rem;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  
  @media (max-width: 350px) {
    .domain-name {
      font-size: 1.8rem; /* Further reduce font size for very small screens */
    }
  
    .team-member {
      width: 90%;
      padding: 10px;
    }
  
    .profile-pic {
      width: 150px;
      height: 200px;
      margin-bottom: 10px;
      margin-top: 20px;
    }
  
    .member-name {
      font-size: 1.4rem;
    }
  
    .member-lastname {
      font-size: 0.85rem;
      margin-top: -2px;
      margin-bottom: 6px;
    }
  }