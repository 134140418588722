@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.landing-main {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    padding: 2rem 5rem;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    padding-bottom: 25rem;
    border-bottom: 2px solid red;
}

.landing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tagline {
    padding-left: 0.25rem;
    color: #FF262C;
    font-size: larger;
    padding-bottom: 1rem;
}

.theme {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 13rem;
}

.theme-heading {
    text-transform: uppercase;
    font-size: 3rem;
    color: white;
}

.theme-button {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: auto;
    padding: 0 5px 5px 0;
}

.buy-tickets {
    height: 4rem;
    width: 25rem;
    background: linear-gradient(to right, #741A1A, #FF0A0A);
    border: none;
    border-radius: 8px;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: background 500ms ease-in-out, transform 500ms ease-in-out, font-size 300ms ease-in-out, font-weight 300ms ease-in-out;
}

.buy-tickets a {
    text-decoration: none;
    color: inherit;
}

.buy-tickets:hover {
    background: linear-gradient(to right, #5e1414, #d00909);
    transform: scale(1.05);
    font-size: 1.65rem;
    font-weight: 700;
}

.banner {
    margin-top: 5rem;
    width: 100%;
    height: 40rem;
}

.banner-img {
    width: 100%;
    height: 100%;
}

.mobile-banner {
    display: none; /* Hide mobile banner by default */
  }

/* Responsive adjustments */
@media (max-width: 1024px) {
    .landing-main {
        margin: 2rem;
        padding-bottom: 15rem;
    }
    
    .theme-heading {
        font-size: 2.5rem;
    }
    
    .buy-tickets {
        width: 20rem;
        font-size: 1.25rem;
    }
    
    .banner {
        height: 30rem;
    }
}

@media (max-width: 768px) {
    .landing-main {
        margin: 1rem;
        padding-bottom: 0rem;
    }

    .tagline {
        text-align: center;
    }

    .theme {
        flex-direction: column;
        height: auto;
        text-align: center;
    }
    
    .theme-heading {
        font-size: 1.75rem;
        margin-bottom: 1rem;
    }
    
    .buy-tickets {
        width: 18rem;
        height: 3.5rem;
        font-size: 1.2rem;
    }
    
    .banner {
        height: 20rem;
    }
}

@media (max-width: 480px) {
    .landing-main {
        height: auto;
        padding: 0rem 5rem 5rem 5rem!important;
    }
    .desktop-banner {
        display: none; /* Hide desktop banner on mobile devices */
      }
    
      .mobile-banner {
        display: block; /* Show mobile banner on mobile devices */
      }
      
    .landing-main {
        margin: 1rem;
        padding-bottom: 10rem;
    }
    
    .tagline {
        font-size: medium;
        text-align: center;
        padding: 0.5rem;
    }
    
    .theme-heading {
        font-size: 1.25rem;
        line-height: 2.2rem;
    }
    
    .buy-tickets {
        width: 16rem;
        height: 3rem;
        font-size: 1rem;
    }
    
    .banner {
        height: 20rem;
        margin-top: 3rem;
    }
}
