@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

.header-main {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 5rem;
}

/* Responsive Logo */
.ted-logo {
    display: flex;
    align-items: center;
    height: 7rem;
    width: 16rem;
}

/* Responsive Navbar */
.list-items {
    display: flex;
    gap: 2rem;
    list-style-type: none;
    cursor: pointer;
    color: white;
    text-decoration: none;
}

.list-home, .list-about, .list-speakers, .list-team {
    transition: font-weight 150ms ease-in-out, transform 150ms ease-in-out;
}

.list-items li a {
    text-decoration: none;
    color: inherit;
}

.list-home:hover, .list-about:hover, .list-speakers:hover, .list-team:hover {
    font-weight: bold;
    transform: scale(1.05);
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .header {
        padding: 1rem 3rem;
    }
    
    .ted-logo {
        height: 5rem;
        width: 12rem;
    }
    
    .list-items {
        gap: 1.5rem;
    }
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
        padding: 1rem 2rem;
    }
    
    .ted-logo {
        margin-bottom: 1rem;
        height: 4rem;
        width: 10rem;
    }
    
    .list-items {
        gap: 1rem;
    }
}

@media (max-width: 480px) {
    .ted-logo {
        height: 10rem;
        width: 18rem;
    }

    .list-items {
        display: none;
    }
    
    .header-main {
        position: relative;
    }
}
